import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import './serviceList.scss'


const ServicesList = (props) => {
    const { list } = props

    const isEven = function(value) {
        if (value % 2 === 0){
            return true;
        }else{
            return false;
        }
    }

    return (

        <div className='services'>
            {list.map((service, index) =>
                <div key={index} className="serviceLists">
                  {isEven(index) ? (
                    <div className="Left">
                        <section>
                            <h2>{service.node.title}</h2>
                            <p>{service.node.metaDescription}</p>
                            <div className="second-button"><a href={'/services/' + service.node.slug + '/'}><strong>Learn More</strong></a></div>
                        </section>
                        <div className="imageContainer">
                            <GatsbyImage image={getImage(service.node.articleImage)} alt={service.node.title} />
                        </div>
                    </div>
                  ) : (
                    <div className="Right">
                        <div className="imageContainer">
                            <GatsbyImage image={getImage(service.node.articleImage)} alt={service.node.title} />
                        </div>
                        <section>
                            <h2>{service.node.title}</h2>
                            <p>{service.node.metaDescription}</p>
                            <div className="second-button"><a href={'/services/' + service.node.slug + '/'}><strong>Learn More</strong></a></div>
                        </section>
                    </div>
                  )}
                </div>
            )}
        </div>
    )
}

export default ServicesList